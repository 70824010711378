import logo from './logo.svg';
import './App.css';
import duck from "./duck.webp"
import next from "./next.svg"
import React, {useState, useRef, useEffect} from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import arrow from './arrow.svg'
import data from "./p.json"
import play from "./image.png"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAPAthyjIj9LQR2HMUZG7CBiEYh2uXBu50",
  authDomain: "duckdev-3cd51.firebaseapp.com",
  projectId: "duckdev-3cd51",
  storageBucket: "duckdev-3cd51.appspot.com",
  messagingSenderId: "796710835183",
  appId: "1:796710835183:web:22b66c94ca032c2f85dc1a",
  measurementId: "G-KJGH9GDKEE"
};

// Initialize Firebase
const fire = initializeApp(firebaseConfig);
const analytics = getAnalytics(fire);

function App() {

  const [Project,setProject] = useState(0)
  const pg1 = useRef(null)
  const pg2 = useRef(null)
  const pg3 = useRef(null)
  const [src, setSrc] = useState("")
  const [vis, setVis] = useState(false)

  return (
    <div id='asg'>
      <div id='vidMax' className={vis ? "" : "invis"}>
        <video src={src} controls={true}></video>
        <button onClick={() => {
          setVis(false)
          setSrc("")
        }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill='white' d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg></button>
      </div>
      <section className='page' ref={pg1}>
        <div id='bgcon'>
          <div id='duckBG'></div>
        </div>
        <div className='contents'>
          <div className='card'>
            <button onClick={() => {pg2.current?.scrollIntoView({behavior: 'smooth'});}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" id="down-arrow"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M11 5v11.17l-4.88-4.88c-.39-.39-1.03-.39-1.42 0-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L13 16.17V5c0-.55-.45-1-1-1s-1 .45-1 1z"></path></svg></button>
            <h1>View More</h1>
          </div>

          <div className='info-card'>
            <img src={duck} />
            <h1>ducki</h1>
            <h2>@.str4d</h2>
          </div>

          <div className='glass-card'>
            <h1>
              Hi, Im ducki.
            </h1>

            <h3>
              I am a professional scripter with 4+ years of experience. I have worked with clients for 2+ years and made things such as a fully working catalog editor, a full game inspired by "STFO" and a minor part in an upcoming game called "Cozy Cuddle".
            </h3>
          </div>

        </div>
      </section>
      <section className='page p2' ref={pg2}>
        <section class='feature f1'>
        <img src={data[5].src} />
          <div>
            <h1>
              {data[5].Name}
            </h1>

            <h3>
              {data[5].Description}
            </h3>
          </div>
        </section>
        <section className='feature f2'>
        <img src={data[6].src} />
            <div>
              <h1>
                {data[6].Name}
              </h1>

              <h3>
                {data[6].Description}
              </h3>
            </div>
        </section>
      </section>
      <section className='page p3' ref={pg3}>
        <div id='vids'>
        {
          data.map((v,idx)=>{
            if (v.Type === "video") {
              return <div className='pVidCon'>
                    <video className='pVid' src={v.src}></video>
                    <div className='pCover'></div>
                    <h1>{v.Name}</h1>
                    <button className='pBtn' onClick={() => { console.log('viai');setSrc(v.src); setVis(true)}}> <img src={play} /></button>
                  </div>
            }
           })
        }
        </div>
      </section>
      <footer>
        <h1>Contact me</h1>
        <div>
          <a href='https://discordapp.com/users/994541468687536138'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="#000000" d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z"/></svg></a>
          <a href='https://twitter.com/duckiDUD'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#000000" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/></svg></a>
          <a href='https://www.roblox.com/users/1501081581/profile'><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"viewBox="0 0 70 70"><g><path fill='black' d="M23.4,4.5L4.5,46.6l42.1,18.9l18.9-42.1L23.4,4.5z M37.6,41.7l-9.3-4.2l4.2-9.3l9.3,4.2L37.6,41.7z"/></g></svg></a>
        </div>
      </footer>
    </div>
  );
}

export default App;
